/* You can add global styles to this file, and also import other style files */
@import '/src/custom-theme.scss';

$primary: map-get($pet-sale-theme, primary);
$accent: map-get($pet-sale-theme, accent);
$warn: map-get($pet-sale-theme, warn);

$light-gray: map-get($pet-sale-theme, light-gray);

html,
body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-image: url('/assets/images/patitas_1.png');
}

.ngx-gallery {
    display: block;
    margin-bottom: 20px;
}

.container {
    padding: 30px;
}

.dropzone {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 200;
    height: 300px;
    border-radius: 5px;
    background: white;
    margin: 10px 0;
    overflow: auto;
    padding: 10px;

}

.dropzone-hover {
    border: 2px solid #f16624;
    color: #dadada !important;
}

.parent-dropzone {
    height: 200px;
}

.loading-area {
    position: absolute;
    z-index: 10000;
    min-width: 100%;
    min-height: 100%;
}

.loading-card {
    width: 300px;
    z-index: 10001;
}


.blured {
    opacity: 50%;
}

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
    transform: none !important;
}

.post-card {
    padding: 0 !important;
    height: 350px;
    width: 250px;
    border: solid;
    border-color: transparent;
    transition: 0.5s;

}

.post-card:hover {
    border-color: map_get($pet-sale-primary, 500);
}

.image-container {
    height: 200px;
    width: 100%;
    overflow: hidden;

    display: inline-block;
    position: relative;
}

.image-container div {
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 4px;
    background-color: rgba($color: lightgray, $alpha: 0.3);

}


.box_container {
    background: rgba(100, 100, 100, 0.2);
    border-radius: 10px;
    padding: 10px;

}


.error_message {
    color: red;
    font-size: 22px;
}

.warning_message {
    color: orange;
    font-size: 22px;
}

.info_message {
    color: map_get($pet-sale-primary, 500);
    font-size: 18px;
}


.fill-card {
    border-color: transparent !important;

}

.fill-card:hover {
    border-color: transparent !important;
}

.secondary-btn {
    color: map_get($pet-sale-primary, 500);
    background-color: white;
}

.text-danger {
    color: map_get($pet-sale-warn, 500);
    font-size: 11px;
}