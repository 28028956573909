// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $pet-sale-primary: mat-palette($mat-indigo);

$green-pallete: (
    50 : #e6ece8,
    100 : #c0cfc5,
    200 : #96b09f,
    300 : #6b9078,
    400 : #4c785b,
    500 : #2c603e,
    600 : #275838,
    700 : #214e30,
    800 : #1b4428,
    900 : #10331b,
    A100 : #71ff98,
    A200 : #3eff73,
    A400 : #0bff4e,
    A700 : #00f142,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$light-green: (
    50 : #f3f7f3,
    100 : #e0eae2,
    200 : #cbddcf,
    300 : #b6cfbb,
    400 : #a7c4ad,
    500 : #97ba9e,
    600 : #8fb396,
    700 : #84ab8c,
    800 : #7aa382,
    900 : #699470,
    A100 : #ffffff,
    A200 : #d8ffdf,
    A400 : #a5ffb5,
    A700 : #8bff9f,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$gray-pallete: (
    50 : #fbfbfa,
    100 : #f5f4f2,
    200 : #efedea,
    300 : #e9e5e2,
    400 : #e4e0db,
    500 : #dfdad5,
    600 : #dbd6d0,
    700 : #d7d0ca,
    800 : #d2cbc4,
    900 : #cac2ba,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);




$pet-sale-primary: mat-palette($green-pallete, 500);

$pet-sale-accent: mat-palette($light-green, 500);


// The warn palette is optional (defaults to red).
$pet-sale-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pet-sale-theme: mat-light-theme((color: (primary: $pet-sale-primary,
                accent: $pet-sale-accent,
                warn: $pet-sale-warn,
                light-gray: $gray-pallete )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($pet-sale-theme);